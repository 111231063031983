import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <img src="/img/footer-new.png" alt="" />
      {/* <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <img
              src="/img/footer-logo.png?v=1"
              alt=""
              className="footer-logo"
            />
          </div>
          <div className="col-12">
            <p className="copyright">
              © 2023​ Electronic Arts Inc. Electronic arts, EA, EA Sports,{" "}
              <br />
              The EA sports logo, EA sports FC, And the EA sports FC Logo are
              trademarks of electronic arts inc.
            </p>
          </div>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
